import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form } from "reactstrap";
import Moment from 'moment';
import user_active from "../../Images/user_active.png";
import payment from "../../Images/payment.png";
import lapi from "../../Images/lapi.png";
import share from "../../Images/share.png";
import photo from "../../Images/photo.png";
import edit12 from "../../Images/edit12.png";
import visa from "../../Images/visa.png";
import paypal from "../../Images/paypal.png";
import master_card from "../../Images/master_card.png";
import material_edit from "../../Images/Icon-material-edit.png";
import video_outer from "../../Images/video_outer.png";
import play_button from "../../Images/play-button-arrowhead.png";
import user_add from "../../Images/user_add.png";
import icon_eyes from "../../Images/icon-eyes.png";
// import video_img from "../../Images/video_img.png";
import video_img from "../../Images/audio_img.png";
import audio_img from "../../Images/congratulation_audio.jpg";
import insurance from "../../Images/insurance.png";
import img_side from "../../Images/img_side.png";
import { profile, updateProfile,getPlans, getCountries, createCheckoutSession,changePassword, logOut, myEternaviewList, updateEternaviewPermission, sendInviteToView, listInviteUsers, revokePermissionView, privacySettings, inviteSharedWithMe, viewersList, postPrivacyForm, getPracticeEternaviews, getSubscriptionDetail, cancelSubscription, requestReactivation, renewSubscription, editTitle, getEternaVideos, getMyEternaviews, verifyUserSubscription, updatePaymentMethod } from "../../redux/actions";
import Geocode from "react-geocode";
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
// import vector_audio from "../../Images/vector_audio.jpg";
import vector_audio from "../../Images/congratulation_audio.jpg";
// import vector_audio from "../../Images/audio.gif";
import copy from "copy-to-clipboard";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import user_default from "../../Images/user-default.jpeg";
import { FRONTEND_ENDPOINT } from "../../config/connection";
import connection from "../../config/connection";
import DownloadEterna from "./components/downloadEterna";
import { Modal } from "react-bootstrap";
import {CardElement, Elements, useStripe, useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./Stripe/stripePayementUpdate";
import $ from 'jquery';
import Swal from "sweetalert2";
const re = /^[0-9\b]+$/;
const alphaRe = /^[A-Za-z ]+$/;
const stripePromise = loadStripe(process.env.REACT_APP_SECRET_KEY);
class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            renewalPlans:[],
            allPlans:[],
            first_name: '',
            last_name: '',
            avatar: '',
            email: '',
            phone: '',
            dob: '',
            bio: 'Please enter your bio!!!',
            country: '',
            lat: '',
            long: '',
            imgSrc: photo,
            countries: [],
            sectionType: false,
            new_password: '',
            old_password: '',
            confirm_password: '',
            month: '',
            date: '',
            year: '',
            phoneError: '',
            value: '',
            setValue: '',
            checkimg: 0,
            platform: '',
            views: [],
            invite_name: '',
            invite_email: '',
            view_id: '',
            inviteeLists: [],
            nodding_video: '',
            invite_id: '',
            access_permission: '',
            copyText: '',
            myViewers: [],
            allowed_creator_viewed: 'false',
            allowed_third_party_viewed: 'false',
            folks_behind: 'false',
            keep_updated: 'false',
            author: '',
            settingid: '',
            viewerLikes: '',
            sharedWithMe: [],
            practiceViews: '',
            subscriptions: [],
            current_plans: [],
            plan_info: '',
            eterna_title: false,
            input_title: '',
            clickSave: false,
            sixtyInteract: false,
            ninetyInteract: false,
            zeroInteract: false,
            graceZeroInteract: false,
            subscription_id: '',
            card_name: '',
            card_number: '',
            card_expiry_month: '',
            card_expiry_year: '',
            card_cvv: '',
            disableUpdate: false,
            showModal: false,
            changeMade: false
        };

        this.handleProfileSubmit = this.handleProfileSubmit.bind(this);
        this.onInputchange = this.onInputchange.bind(this);
        this.changePermission = this.changePermission.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onInputEternachange = this.onInputEternachange.bind(this);
    }
    componentWillUnmount() {
        this.unlisten();
    }
    componentDidUpdate() {
        window.onpopstate = event => {
            if (this.state.changeMade == true) {
                Swal.fire({
                    title: 'Would you like to save your changes?',
                    showDenyButton: true,
                    confirmButtonText: 'Yes',
                    denyButtonText: `No`,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.submitPrivacy();
                    } else if (result.isDenied) {
                        this.setState({ changeMade: false });
                    }
                })
            }
        }
    }
    componentDidMount() {
        this.getAllPlan()

        $('a[data-toggle="pill"]').on('.shown.active', function (e) {
            // e.target // newly activated tab
            // e.relatedTarget // previous active tab
            alert("hello");
        })


        //   performance.navigation.type


        this.unlisten = this.props.history.listen((location, action) => {
            if (this.state.changeMade) {
                Swal.fire({
                    title: 'Would you like to save your changes?',
                    showDenyButton: true,
                    confirmButtonText: 'Yes',
                    denyButtonText: `No`,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.submitPrivacy();
                    } else if (result.isDenied) {
                        this.setState({ changeMade: false });
                    }
                })
            }
        });



        Geocode.setApiKey("AIzaSyAJ3irKnv73cWo0nFUI2abbSZnxq3CQ_mg");
        Geocode.setLanguage("en");
        if (this.props.auth.loginUserToken != "") {
            this.props.profile({ token: this.props.auth.loginUserToken }, (res) => {
                if (res.data) {
                    this.setState({
                        first_name: res.data.first_name,
                        last_name: res.data.last_name,
                        email: res.data.email,
                        phone: res.data.phone,
                        bio: res.data.bio,
                        country: res.data.country
                    });

                    if (res.data.plateform) {
                        this.setState({ platform: res.data.plateform });
                    }
                    if (res.data.avatar != "") {
                        this.setState({ imgSrc: res.data.avatar });
                        this.setState({ checkimg: 1 });
                    }
                    if (res.data.birth_date != '') {
                        let split = res.data.birth_date.split('-');
                        this.setState({ date: split[2], month: split[1], year: split[0] });
                    }
                }
            });

            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    (pos) => { this.setState({ lat: pos.coords.latitude, long: pos.coords.longitude }) },
                    (err) => { console.log(err); }
                );
            } else {
                alert("Your Browser doesn't support location service !");
            }

            this.props.getCountries({ token: this.props.auth.loginUserToken }, (res) => {
                this.setState({ countries: res.data });
            });

            this.getMyEternaviews();

            this.props.getPracticeEternaviews(this.props.auth.loginUserToken, (res) => {
                if (!res.error || !res.msg) {
                    if (res.data) {
                        // let eterna = [];
                        // eterna.push(res.data);
                        this.setState({ practiceViews: res.data });

                        this.videoJsOptions1 = { // lookup the options in the docs for more options
                            autoplay: true,
                            controls: false,
                            responsive: true,
                            bigPlayButton: false,
                            fluid: true,
                            muted: true,
                            playsinline: true,
                            aspectRatio: '16:9',
                            width: "auto",
                            height: "auto",
                            sources: [{
                                src: res.data.nodding_video,
                                type: 'application/x-mpegURL'
                            }]
                        }

                        this.practice_player = videojs(this.practNode, this.videoJsOptions1, function onPlayerReady() {
                            // print version information at startup
                            console.log('onPlayerReady', this)
                        });

                        // device is ready
                        this.practice_player.on('deviceReady', () => {
                            console.log('device is ready!');
                        });
                    }
                }
            });

            this.getEternaviewsShared();
            this.getEternaSetting();
            this.getSubscription();

        } else {
            localStorage.clear();
            window.location.href = connection.homePage
            // this.props.history.replace("/");
        }

    }

    getMyEternaviews() {
        this.props.myEternaviewList(this.props.auth.loginUserToken, (res) => {
            if (!res.error) {
                if (res.data && res.data.length > 0) {

                    this.setState({ views: res.data });

                    res.data.map((view, index) => {
                        // console.log(view.nodding_video);
                        if (view.nodding_video && view.nodding_video != "") {
                            // console.log('check nodding')
                            view.videoJsOptions = { // lookup the options in the docs for more options
                                autoplay: true,
                                controls: false,
                                responsive: true,
                                bigPlayButton: false,
                                fluid: true,
                                muted: true,
                                aspectRatio: '16:9',
                                width: "auto",
                                height: "auto",
                                playsinline: true,
                                sources: [{
                                    src: view.nodding_video,
                                    type: 'application/x-mpegURL'
                                }]
                            }

                            view.player = videojs(view.viewEternaNode, view.videoJsOptions, function onPlayerReady() {
                                // print version information at startup
                                console.log('onPlayerReady', this)
                            });

                            // device is ready
                            view.player.on('deviceReady', () => {
                                console.log('device is ready!');
                            });
                        }

                        view.permission = view.permission + '+' + view.id;
                        view.name = view.id;
                        view.value = view.title;
                        view.edit = false;
                    });


                    this.props.verifyUserSubscription(this.props.auth.loginUserToken, (res) => {
                        if (res.data) {
                            this.setState({ verifyPlanDetail: res.data });
                            if (res.data.subscribe_plan__no_of_query > 0) {
                                let sixtyVal = res.data.subscribe_plan__no_of_query - (Math.floor(0.60 * res.data.subscribe_plan__no_of_query));
                                if (sixtyVal == res.data.updated_details.no_of_query) {
                                    this.setState({ sixtyInteract: true });
                                }

                                let ninetyVal = res.data.subscribe_plan__no_of_query - (Math.floor(0.90 * res.data.subscribe_plan__no_of_query));
                                if (ninetyVal == res.data.updated_details.no_of_query) {
                                    this.setState({ ninetyInteract: true });
                                }

                                if (res.data.updated_details.no_of_query == 0 && res.data.updated_details.grace_no_of_query > 0) {
                                    this.setState({ zeroInteract: true });
                                } else if (res.data.updated_details.no_of_query == 0 && res.data.updated_details.grace_no_of_query == 0) {
                                    console.log('tanisha');
                                    this.setState({ graceZeroInteract: true });
                                }
                            }

                        }
                    });

                }

            }
        });

    }

    onInputchange(event) {
        if (event.target.name == 'country') {
            // Geocode.fromAddress(event.target.value).then(
            //     (response) => {
            //       const { lat, lng } = response.results[0].geometry.location;
            //       console.log(lat, lng);
            //     },
            //     (error) => {
            //       console.error(error);
            //     }
            // );f
        }
        // if (event.target.name == "phone") {
        //     var test = /^\+?(\d\-|\d)+\d$/ig.test(event.target.value);
        //     if (test) {
        //         this.setState({ phoneError: "" });
        //         this.setState({
        //             [event.target.name]: event.target.value
        //         });
        //     } else {
        //         this.setState({ phoneError: "Please enter valid phone number." });
        //     }

        // } else {
        //  }

        if (event.target.name == 'access_permission') {
            let split = this.state.permission.split("+");
            this.props.updateEternaviewPermission({ "id": split[1], "permission": split[0], "access_permission": event.target.value }, this.props.auth.loginUserToken, (res) => {
                if (res.msg) {
                    toast("Eternaview permissions have been updated successfully", {
                        position: "top-right",
                        autoClose: 5000,
                        type: 'success',
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            });
        }

        let cardElements = ['card_number', 'card_expiry_month', 'card_expiry_year', 'card_cvv'];

        if (cardElements.indexOf(event.target.name) != -1 && !re.test(event.target.value) && 0 < event.target.value.length) {
            return false;

        } else {

            if ('card_name' == event.target.name && '' !== event.target.value && !alphaRe.test(event.target.value)) {
                return false
            }

            if ('card_name' == event.target.name && 50 < event.target.value.length) {
                return false
            }

            if ('card_number' == event.target.name && 16 < event.target.value.length) {
                return false
            }
            if ('card_expiry_month' == event.target.name && (12 < event.target.value || 2 < event.target.value.length)) {
                return false
            }
            if ('card_expiry_year' == event.target.name && 2 < event.target.value.length) {
                return false
            }
            if ('card_cvv' == event.target.name && 3 < event.target.value.length) {
                return false
            }

            this.setState({
                [event.target.name]: event.target.value
            });
        }


    }

    onInputEternachange(event) {
        let views = this.state.views.slice();
        for (let i in views) {
            if (views[i].name == event.target.name) {
                views[i].value = event.target.value;
                this.setState({ views: views });
                break;
            }
        }
    }

    _onChange = event => {
        event.preventDefault();
        // Assuming only image

        console.log(event.target.files);
        if (event.target.files && event.target.files[0]) {

            if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/gif" || event.target.files[0].type == "image/svg") {
                this.setState({ 'avatar': event.target.files[0] });
                this.setState({ 'checkimg': 1 });

                var file = event.target.files[0];
                var reader = new FileReader();
                var url = reader.readAsDataURL(file);

                reader.onloadend = function (e) {
                    this.setState({
                        imgSrc: [reader.result]
                    })
                }.bind(this);
            } else {
                toast('Please select correct image files.', {
                    position: "top-right",
                    autoClose: 3000,
                    type: 'error',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            }
        }

    }

    logout = (e) => {
        e.preventDefault();
        this.props.history.replace('/oauth/logmeOut/');
      
        // let payload = {
        //     "grant_type": "password",
        //     "client_id": this.props.auth.credentials.client_id,
        //     "client_secret": this.props.auth.credentials.client_secret,
        //     "token": this.props.auth.loginUserToken
        // }

        // this.props.logOut(payload, (result) => {
        //     if (result.status == true) {
        //         toast("You are successfully logged out of application.", {
        //             position: "top-right",
        //             autoClose: 3000,
        //             type: 'success',
        //             hideProgressBar: false,
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: false,
        //             progress: undefined,
        //         });
        //         window.location.href = connection.homePage
        //         this.props.history.replace('/oauth/logmeOut/');
        //     }

        // });
    };

    change_password = (e) => {
        e.preventDefault();
        this.setState({ sectionType: !this.state.sectionType });
    };

    copyToClipboard = (link) => {
        copy(link);

        toast("Link is copied", {
            position: "top-right",
            autoClose: 2000,
            type: 'success',
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
        });
    }

    handleProfileSubmit = (e) => {

        e.preventDefault();
        if(this.state.first_name == '' || !this.state.first_name ){
            toast("First name is Required", {
                position: "top-right",
                autoClose: 3000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
            return
        }

        let token = this.props.auth.loginUserToken;
        let formData = new FormData();

        this.setState({ clickSave: true });
        // console.log(this.state.sectionType);
        if (this.state.sectionType == false) {
            if (this.state.date !== '') {
                if (this.state.month == '' || this.state.year == '') {
                    toast('Please select month and year from date of birth field.', {
                        position: "top-right",
                        autoClose: 3000,
                        type: 'error',
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                    return;
                }
                formData.append("birth_date", this.state.year + '-' + this.state.month + '-' + this.state.date);
            }
            if (this.state.phone && this.state.phone.length > 20) {
                toast('please enter phone number of valid length.', {
                    position: "top-right",
                    autoClose: 3000,
                    type: 'error',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
                return;
            }

            formData.append("first_name", this.state.first_name);
            formData.append("last_name", this.state.last_name);
            formData.append("email", this.state.email);
            formData.append("phone", this.state.phone);
            formData.append("bio", this.state.bio);
            formData.append("lat", this.state.lat);
            formData.append("lng", this.state.long);

            if (this.state.country != '') {
                formData.append("country", this.state.country);
            }
            if (this.state.avatar != '') {
                formData.append('avatar', this.state.avatar);
            }

            this.props.updateProfile(formData, this.props.auth.loginUserToken, (res) => {
                this.setState({ clickSave: false });
                if (res.data) {
                    if(res.data.access_token) sessionStorage.setItem("token",res.data.access_token)
                    toast('Your profile has been updated successfully.', {
                        position: "top-right",
                        autoClose: 3000,
                        type: 'success',
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });

                    this.setState({
                        first_name: res.data.data.first_name,
                        last_name: res.data.data.last_name,
                        email: res.data.data.email,
                        phone: res.data.data.phone,
                        bio: res.data.data.bio,
                        country: res.data.data.country
                    });

                    if (res.data.data.avatar != "") {
                        this.setState({ imgSrc: res.data.data.avatar });
                    }

                    if (res.data.data.birth_date != '') {
                        let temp = res.data.data;
                        let split = temp.birth_date.split('-');
                        this.setState({ date: split[2], month: split[1], year: split[0] });
                    }
                } else {
                    toast(res.error, {
                        position: "top-right",
                        autoClose: 3000,
                        type: 'error',
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }

            });
        } else {
            if (this.state.old_password == '' || this.state.new_password == '' || this.state.confirm_password == '') {
                toast('Please make sure all fields are filled.', {
                    position: "top-right",
                    autoClose: 3000,
                    type: 'error',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            } else if (this.state.new_password == this.state.confirm_password) {

                let payload = {
                    "old_password": this.state.old_password,
                    "password": this.state.new_password,
                    "password2": this.state.confirm_password
                }

                this.props.changePassword(payload, token, (res) => {
                    if (res.error) {
                        toast(res.error, {
                            position: "top-right",
                            autoClose: 3000,
                            type: 'error',
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                        });
                    } else if (res.msg) {

                        let payload = {
                            "grant_type": "password",
                            "client_id": this.props.auth.credentials.client_id,
                            "client_secret": this.props.auth.credentials.client_secret,
                            "token": this.props.auth.loginUserToken
                        }

                        this.props.logOut(payload, (result) => {
                            if (result.status == true) {
                                toast("Your password has been updated successfully. Please login again to continue.", {
                                    position: "top-right",
                                    autoClose: 3000,
                                    type: 'success',
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                });
                                this.props.history.replace('/oauth/sign-in/');
                            }

                        });
                    }

                });
            } else {
                toast('New Password does not match with Confirm password field.', {
                    position: "top-right",
                    autoClose: 3000,
                    type: 'error',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            }
        }
    };

    getSubscription() {
        this.props.getSubscriptionDetail(this.props.auth.loginUserToken, (result) => {
            if (result.subscriptions) {
                result.current_plan.map((con, index) => {
                    if (con.updated_details.total_time != 0) {
                        let remaining_time = (con.subscribe_plan__total_time * 3600) - con.updated_details.total_time;
                        remaining_time = remaining_time / 3600;

                        con.remaining_time = Math.round((remaining_time + Number.EPSILON) * 100) / 100;
                    }
                    if ('undefined' !== typeof con.updated_details.no_of_edit) {
                        con.ninetyEdit = Math.round((con.subscribe_plan__grace_no_of_edit + con.subscribe_plan__no_of_edit) - (0.9 * (con.subscribe_plan__grace_no_of_edit + con.subscribe_plan__no_of_edit)));
                        // var remainingEdits = con.updated_details.grace_no_of_edit + con.updated_details.no_of_edit;



                        // con.remaining_edit = con.subscribe_plan__no_of_edit - (0.90 * con.subscribe_plan__no_of_edit);
                        // con.remaining_edit = (0.90 * con.subscribe_plan__no_of_edit);
                    }
                    if ('undefined' !== typeof con.updated_details.no_of_query) {
                        con.ninetyQuery = Math.round((con.subscribe_plan__grace_no_of_query + con.subscribe_plan__no_of_query) - (0.9 * (con.subscribe_plan__grace_no_of_query + con.subscribe_plan__no_of_query)));

                        // con.remaining_query = con.subscribe_plan__no_of_query - (0.90 * con.subscribe_plan__no_of_query);
                        // con.remaining_query = (0.90 * con.subscribe_plan__no_of_query);
                    }
                    result.subscriptions.map((sub, ind) => {
                        if (index == ind) {
                            con.subscription = sub;
                        }
                    });
                });

                this.setState({ subscriptions: result.subscriptions, current_plans:result.current_plan});
            }
        });
    }

    handleCancelSubscription = (value) => {
        // e.preventDefault();
        this.props.cancelSubscription({ subscription_id: value.subscription_id, "status": value.is_cancel == true ? false : true }, this.props.auth.loginUserToken, (result) => {
            if (result.status) {
                if (value.is_cancel == true) {
                    toast("Automatic renewal is now turned on.", {
                        position: "top-right",
                        autoClose: 5000,
                        type: 'success',
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                    });

                } else {
                    toast("You have turned off automatic renewal. Your Eternaview will no longer be accessible after the subscription expires.", {
                        position: "top-right",
                        autoClose: 5000,
                        type: 'success',
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                    });
                }

                this.getSubscription();

            }
        });
    }
    getAllPlan =()=>{
        this.props.getPlans(
            this.props.auth.loginUserToken ?this.props.auth.loginUserToken : "",
             (result) => {
               if(result?.data){
                const plansWithParent = result?.data?.filter(curr=>(curr.parent_plan))
                const plans = result?.data?.filter(curr=>(curr.parent_plan == null))
                  this.setState({allPlans:plansWithParent,renewalPlans:plans})
               }
             }
           )
    }
    renewSubscription = (param) => {
        let planDetails  = []
        planDetails =this.state.allPlans.filter(curr => curr.parent_plan == param.subscribe_plan__id )   
     
        if(planDetails.length == 0 ){
            planDetails =this.state.allPlans.filter(curr => curr.id == param.subscribe_plan__id )   
        }
        console.log(planDetails,"<- Plan Details");
        if(planDetails){
             localStorage.setItem("priceLookupKey",planDetails[0].plans[0].id);
             localStorage.setItem("planId", planDetails[0].plans[0].plan);
                let body = {
                    lookup_key: planDetails[0].plans[0].plan,
                    domain: `${FRONTEND_ENDPOINT}/home/plans/${param.subscribe_plan__category__id}+${param.subCategory}+${param.typecreates}`,
                    planAmount: planDetails[0].plans[0].amount,
                    basePath: `${window.location.origin}/home/plans/${param.subscribe_plan__category__id}+${param.subCategory}+${param.typecreates}`,
                  };
                this.props.createCheckoutSession(body,this.props.auth.loginUserToken,(res)=>{
                    if(res.session_url)
                    window.location.href = res.session_url;
                    if(res.error)
                    alert("Please try again latter.")
                })
            }

         
    }

    handleRenewSubscription = (con) => {
        //   e.preventDefault();
//  "planAmount": con.price__amount, Backupline in body
        let body = {
            "domain": `${FRONTEND_ENDPOINT}/home/profile/`,
            "plan_id": con.subscribe_plan__id,
            "planAmount": con.total_paid_amount - con.price__amount,
            "subscription_id": con.subscription_id,
            "id": con.id
        }
        this.props.renewSubscription(body, this.props.auth.loginUserToken, (result) => {
            if (result.data && result.data.id) {
                toast("Subscription has been renewed successfully.", {
                    position: "top-right",
                    autoClose: 3000,
                    type: 'success',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });

                this.getSubscription();
            }
            // window.location.href = result.session_url;
        });
    }

    sendInvite = (e) => {
        e.preventDefault();

        if (this.state.invite_email == '') {
            toast("Please enter invitee email.", {
                position: "top-right",
                autoClose: 5000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
            return;
        } else {
            this.props.sendInviteToView({ "user_list": [{ name: this.state.invite_name, email: this.state.invite_email }], "view_id": this.state.view_id }, this.props.auth.loginUserToken, (res) => {
                this.setState({ invite_name: '', invite_email: '' });
                if (res.msg) {
                    this.getMyEternaviews();
                    toast("Invite has been sent successfully.", {
                        position: "top-right",
                        autoClose: 5000,
                        type: 'success',
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                    });
                }

            });
        }
    }

    changePermission(event) {
        let val = event.target.value;
        this.setState({ permission: event.target.value });
        let split = val.split('+');
        this.props.updateEternaviewPermission({ "id": split[1], "permission": split[0] }, this.props.auth.loginUserToken, (res) => {
            if (res.msg) {
                toast("Eternaview permissions have been updated successfully", {
                    position: "top-right",
                    autoClose: 5000,
                    type: 'success',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
                this.getMyEternaviews();
            }
        });
    }

    checkModalId(con) {
        // this.setState({ view_id: con.id });
        this.setState({ view_id: con.id, shared_link: con.shared_links, permission: con.permission, access_permission: con.access_permission });
        this.props.listInviteUsers(con.id, this.props.auth.loginUserToken, (res) => {
            // if (res.data.length > 0) {
            this.setState({ inviteeLists: res.data });
            // }
        });
    }

    // cancelAutomatic(con) {
    //     this.setState({ plan_info: con });
    // }

    // turnAutomatic(con) {
    //     this.setState({ plan_info: con });
    // }

    removePermission() {
        this.props.revokePermissionView(this.state.view_id, { "user_id": this.state.invite_id }, this.props.auth.loginUserToken, (res) => {
            if (res.msg) {
                toast("Permission is revoked from user", {
                    position: "top-right",
                    autoClose: 5000,
                    type: 'success',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
                this.setState({ invite_id: '' });
                this.getAllViewers(this.state.view_id);
                this.getMyEternaviews();
            }

        })
    }

    getEternaviewsShared() {
        this.props.inviteSharedWithMe(this.props.auth.loginUserToken, (res) => {
            if (res.data && res.data.length > 0) {
                this.setState({ sharedWithMe: res.data });

                res.data.map((view, index) => {
                    if (view.trans_coded_url && view.trans_coded_url != "") {
                        view.videoJsOptions = { // lookup the options in the docs for more options
                            autoplay: false,
                            controls: true,
                            responsive: true,
                            bigPlayButton: true,
                            fluid: true,
                            muted: false,
                            playsinline: true,
                            sources: [{
                                src: view.trans_coded_url,
                                type: 'application/x-mpegURL'
                            }]
                        }

                        view.player = videojs(view.viewShared, view.videoJsOptions, function onPlayerReady() {
                            // print version information at startup
                            console.log('onPlayerReady', this)
                        });

                        // device is ready
                        view.player.on('deviceReady', () => {
                            console.log('device is ready!');
                        });
                    }

                });
            }
        });
    }

    getEternaSetting() {
        this.props.privacySettings(this.props.auth.loginUserToken, (res) => {
            if (res.data) {
                this.setState({
                    allowed_creator_viewed: res.data.allowed_creator_viewed,
                    allowed_third_party_viewed: res.data.allowed_third_party_viewed,
                    folks_behind: res.data.folks_behind,
                    keep_updated: res.data.keep_updated,
                    author: res.data.author,
                    settingid: res.data.settingid

                })
            }
        });
    }

    handleChange(event) {

        this.setState({
            [event.target.name]: event.target.value,
            changeMade: true
        });
    };

    submitPrivacy = (e = null) => {
        if (e != null)
            e.preventDefault();

        const payload = {
            "allowed_creator_viewed": this.state.allowed_creator_viewed == 'true' ? true : false,
            "allowed_third_party_viewed": this.state.allowed_third_party_viewed == 'true' ? true : false,
            "keep_updated": this.state.keep_updated == 'true' ? true : false,
            "folks_behind": this.state.folks_behind == 'true' ? true : false
        }
        this.props.postPrivacyForm(payload, this.props.auth.loginUserToken, (res) => {
            this.setState({
                changeMade: false
            })
            toast('Settings Updated.', {
                position: "top-right",
                autoClose: 2000,
                type: 'success',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        });
    }

    getAllViewers(id) {
        this.props.viewersList(id, this.props.auth.loginUserToken, (res) => {
            if (res.data.allowed_user && res.data.allowed_user.length > 0) {
                let data = res.data.allowed_user;
                this.setState({ viewersList: data, viewerLikes: res.data.likes_count });
            }
        });
    }

    revokeModal(id) {
        this.setState({ invite_id: id });
    }

    requestActivation = (e) => {
        e.preventDefault();

        this.props.requestReactivation(this.state.views[0].id, this.props.auth.loginUserToken, (res) => {
            if (res.msg) {
                toast("Admin is notified about your request via email, please wait for further confirmation.", {
                    position: "top-right",
                    autoClose: 5000,
                    type: 'success',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            }
        })
    }

    editEternaTitle = (con) => {
        con.edit = true;
        // this.setState({ eterna_title: true, input_title: con.title });
        this.setState({ eterna_title: true, [`${con.id}`]: con.title });
    };

    submitTitle = (con) => {
        this.props.editTitle({ "id": con.id, "title": this.state.input_title }, this.props.auth.loginUserToken, (res) => {
            if (res.msg) {
                this.setState({ eterna_title: false });
                this.getMyEternaviews();
            }
        });
    }

    paymentMethod = (con) => {
        this.setState({
            subscription_id: con.subscription.id,
            card_name: '',
            card_number: '',
            card_expiry_month: '',
            card_expiry_year: '',
            card_cvv: '',
            showModal: true
        })
    }

    resetPaymentMethod = () => {
        this.setState({
            subscription_id: '',
            card_name: '',
            card_number: '',
            card_expiry_month: '',
            card_expiry_year: '',
            card_cvv: '',
            showModal: false
        })

    }

    savePaymentMethod = (stipeDetails) => {
        console.log(stipeDetails,":- stipeDetails");
        this.setState({ disableUpdate: true })

        let params = {
            subscription_id: this.state.subscription_id,
            payment_id:stipeDetails
            // card_name: this.state.card_name,
            // card_number: this.state.card_number,
            // card_expiry_month: this.state.card_expiry_month,
            // card_expiry_year: this.state.card_expiry_year,
            // card_cvv: this.state.card_cvv
        }



        this.props.updatePaymentMethod(params, this.props.auth.loginUserToken, (res) => {
            if (res.error) {
                toast(res.error, {
                    position: "top-right",
                    autoClose: 2000,
                    type: 'error',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
                this.setState({ disableUpdate: false })
                return false;
            } else {
                toast('Your payment method was updated.', {
                    position: "top-right",
                    autoClose: 2000,
                    type: 'success',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
                this.setState({ disableUpdate: false });
                this.getSubscription();
                this.resetPaymentMethod();
            }
        });


    }
    checkChange() {
        if (this.state.changeMade == true) {
            Swal.fire({
                title: 'Would you like to save your changes?',
                showDenyButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: `No`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    this.submitPrivacy();
                } else if (result.isDenied) {
                    this.setState({ changeMade: false });
                    this.getEternaSetting();

                }
            })
        }

    }
    render() {
        const year = (new Date()).getFullYear();
        const years = Array.from(new Array(500), (val, index) => year - index);
        const { countries, views, permission, inviteeLists, nodding_video, viewersList, allowed_creator_viewed,
            allowed_third_party_viewed, folks_behind, keep_updated, author, settingid, practiceViews, subscriptions, sharedWithMe } = this.state;

        return (
            <section className="create_outer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 top_space4">
                            <div className="nav flex-column nav-pills nav-pills-custom leftside" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <a onClick={(e) => this.checkChange(e)} className="nav-link p-3 shadow active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                    <img src={user_active} />Account Settings</a>
                                <a onClick={(e) => this.checkChange(e)} className="nav-link p-3 shadow" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                    <li><img src={payment} />Payment Information</li>
                                </a>
                                <a className="nav-link p-3 shadow" id="v-pills-privacy-tab" data-toggle="pill" href="#v-pills-privacy" role="tab" aria-controls="v-pills-privacy" aria-selected="false">
                                    <li><img src={insurance} />Privacy Settings</li>
                                </a>
                                <a onClick={(e) => this.checkChange(e)} className="nav-link p-3 shadow" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                    <img src={lapi} />My Eternaviews</a>
                                <a onClick={(e) => this.checkChange(e)} className="nav-link p-3 shadow" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                                    <li><img src={share} />Shared with me</li>
                                </a>
                                <a onClick={(e) => this.checkChange(e)} className="nav-link p-3 shadow" id="v-pills-logout-tab" data-toggle="modal" data-target="#logoutModal" href="#v-pills-logout" role="tab" aria-controls="v-pills-logout" aria-selected="false">
                                    <li><img src={share} />Logout</li>
                                </a>

                                <div className="modal custom_logoutmodal" id="logoutModal">
                                    <div className="modal-dialog">
                                        <div className="modal-content logout_modal_content">
                                            <div className="modal-header">
                                                <h4 className="modal-title">Please Confirm</h4>
                                                <button type="button" className="close" data-dismiss="modal">&times;</button>

                                            </div>
                                            <div className="modal-body custom_body custom_confirm">
                                                <div className="inner_tex45">
                                                    <h4>Are you sure you want to log out of the application?
                                                    </h4>
                                                    <p>Note: After logging out, you might not be able to access most of the information.
                                                    </p>
                                                    <div className="btn_outer99">
                                                        <ul>
                                                            <li className="cancel"><button data-dismiss="modal" className="btn">No</button></li>
                                                            <li className="Save_outer4" data-dismiss="modal" onClick={this.logout}><button className="btn">Yes</button></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="tab-content custom_tabs" id="v-pills-tabContent">
                                <div className="tab-pane fade shadow rounded show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                    <div className="shawdow_outer profile_outer wp_profile_section">
                                        <div className="inner56">
                                            <h4>Account Settings</h4>
                                            {(this.state.sectionType == false && !this.props.auth.loginUserInfo.plateform) &&
                                                <button className="Puchase_now_btn_new" onClick={this.change_password}>Change password</button>}
                                        </div>

                                        {this.state.sectionType == false &&
                                            <div className="row full_width">
                                                <div className="col-lg-4 col-md-12">

                                                    {this.state.avatar == null && <div className={this.state.avatar == null ? "upload" : "upload1"}>
                                                        {this.state.avatar == null && <img src={this.state.imgSrc} />}
                                                        <p>Upload Profile Picture</p>
                                                    </div>}

                                                    <div className={this.state.avatar == null ? "upload_outer" : "upload_outer upload1"}>
                                                        {(this.state.checkimg == 1) && <img src={this.state.imgSrc} />}
                                                        <input type="file" className="no-outline" accept="image/*" id="profile_img" name="profile_img" onChange={this._onChange} />
                                                    </div>
                                                    <div className="edit_outer">
                                                        <img src={edit12} />
                                                    </div>
                                                </div>

                                                <div className="col-lg-8 col-md-12">
                                                    <div className="form_outer">
                                                        <Form onSubmit={(event) => this.handleProfileSubmit(event)} autoComplete="off">
                                                            <div className="input_outer">
                                                                <label>
                                                                    First Name
                                                                </label>
                                                                <input type="text" placeholder="Alianto" name="first_name" value={this.state.first_name} onChange={this.onInputchange} />
                                                            </div>
                                                            <div className="input_outer">
                                                                <label>
                                                                    Last Name
                                                                </label>
                                                                <input type="text" placeholder="Doe" name="last_name" value={this.state.last_name} onChange={this.onInputchange} />
                                                            </div>
                                                            <div className="input_outer">
                                                                <label>
                                                                    Email Address
                                                                </label>

                                                                {this.state.platform == '' &&
                                                                    <input type="email" placeholder="alianto@gmail.com" name="email" required value={this.state.email} onChange={this.onInputchange} />}

                                                                {this.state.platform !== '' &&
                                                                    <input type="email" placeholder="alianto@gmail.com" name="email" required value={this.state.email} onChange={this.onInputchange} readOnly />}
                                                            </div>
                                                            <div className="input_outer">
                                                                <label>
                                                                    Mobile Number
                                                                </label>
                                                                {/* <input type="number" placeholder="855 225 5255" name="phone" value={this.state.phone} onChange={this.onInputchange} /> */}
                                                                <PhoneInput
                                                                    placeholder="Enter phone number"
                                                                    value={this.state.phone}
                                                                    name="phone"
                                                                    onChange={(val) => this.setState({ phone: val })} />
                                                                <p className="errMsg">{this.state.phoneError}</p>
                                                            </div>
                                                            <div className="input_outer">
                                                                <label>
                                                                    Country
                                                                </label>
                                                                {/* <input type="text" placeholder="US" name="country" value={this.state.country} onChange={this.onInputchange} /> */}
                                                                <select onChange={(val) => this.setState({ country: val.target.value })} value={this.state.country}>
                                                                    <option value=""> Select country </option>
                                                                    {
                                                                        countries && countries.map((con, index) => {
                                                                            return <option key={`country${index}`} value={con.id}>{con.name}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-12">
                                                                    <div className="input_outer">
                                                                        <label>
                                                                            Date Of Birth
                                                                        </label>
                                                                        <select onChange={(val) => this.setState({ month: val.target.value })} value={this.state.month}>
                                                                            <option value="">Month</option>
                                                                            <option value="01">January</option>
                                                                            <option value="02">February</option>
                                                                            <option value="03">March</option>
                                                                            <option value="04">April</option>
                                                                            <option value="05">May</option>
                                                                            <option value="06">June</option>
                                                                            <option value="07">July</option>
                                                                            <option value="08">August</option>
                                                                            <option value="09">September</option>
                                                                            <option value="10">October</option>
                                                                            <option value="11">November</option>
                                                                            <option value="12">December</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-12">
                                                                    <div className="input_outer">
                                                                        <label className="hide_outer">
                                                                            Optional
                                                                        </label>
                                                                        <select onChange={(val) => this.setState({ date: val.target.value })} value={this.state.date}>
                                                                            <option value=""> Day </option>
                                                                            <option value="01"> 01 </option>
                                                                            <option value="02"> 02 </option>
                                                                            <option value="03"> 03 </option>
                                                                            <option value="04"> 04 </option>
                                                                            <option value="05"> 05 </option>
                                                                            <option value="06"> 06 </option>
                                                                            <option value="07"> 07 </option>
                                                                            <option value="08"> 08 </option>
                                                                            <option value="09"> 09 </option>
                                                                            <option value="10"> 10 </option>
                                                                            <option value="11"> 11 </option>
                                                                            <option value="12"> 12 </option>
                                                                            <option value="13"> 13 </option>
                                                                            <option value="14"> 14 </option>
                                                                            <option value="15"> 15 </option>
                                                                            <option value="16"> 16 </option>
                                                                            <option value="17"> 17 </option>
                                                                            <option value="18"> 18 </option>
                                                                            <option value="19"> 19 </option>
                                                                            <option value="20"> 20 </option>
                                                                            <option value="21"> 21 </option>
                                                                            <option value="22"> 22 </option>
                                                                            <option value="23"> 23 </option>
                                                                            <option value="24"> 24 </option>
                                                                            <option value="25"> 25 </option>
                                                                            <option value="26"> 26 </option>
                                                                            <option value="27"> 27 </option>
                                                                            <option value="28"> 28 </option>
                                                                            <option value="29"> 29 </option>
                                                                            <option value="30"> 30 </option>
                                                                            <option value="31"> 31 </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-12">
                                                                    <div className="input_outer">
                                                                        <label className="hide_outer">
                                                                            Optional
                                                                        </label>
                                                                        <select onChange={(val) => this.setState({ year: val.target.value })} value={this.state.year}>
                                                                            <option value="">Year</option>
                                                                            {
                                                                                years.map((year, index) => {
                                                                                    return <option key={`year${index}`} value={year}>{year}</option>
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="input_outer custom_bia">
                                                                <label>
                                                                    Bio
                                                                    {/* <span>Optional</span> */}
                                                                </label>
                                                                <textarea name="bio" value={this.state.bio} onChange={this.onInputchange}></textarea>
                                                            </div>
                                                            <div className="btn_outer99 custom_b56">
                                                                <ul>
                                                                    {this.state.sectionType == true && <li className="cancel"><button className="btn" type="button" onClick={this.change_password}>Cancel</button></li>}

                                                                    {this.state.clickSave == false && <li className="Save_outer4"><button type="submit" className="Puchase_now_btn_new">Save</button></li>}
                                                                    {this.state.clickSave == true && <li className="Save_outer4"><button type="button" className="Puchase_now_btn_new">Saving</button></li>}
                                                                </ul>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </div>}

                                        {this.state.sectionType == true &&
                                            <div className="row full_width">
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form_outer">
                                                        <Form onSubmit={(event) => this.handleProfileSubmit(event)} autoComplete="off">
                                                            <div className="input_outer">
                                                                <label> Old Password </label>
                                                                <input type="password" placeholder="Old Password" name="old_password" value={this.state.old_password} required onChange={this.onInputchange} />
                                                            </div>
                                                            <div className="input_outer">
                                                                <label> New Password </label>
                                                                <input type="password" placeholder="New Password" name="new_password" value={this.state.new_password} required onChange={this.onInputchange} />
                                                            </div>
                                                            <div className="input_outer">
                                                                <label> Confirm New Password</label>
                                                                <input type="password" placeholder="Confirm New Password" name="confirm_password" value={this.state.confirm_password} required onChange={this.onInputchange} />
                                                            </div>

                                                            <div className="btn_outer99">
                                                                <ul>
                                                                    <li className="cancel"><button className="btn" type="button" onClick={this.change_password}>Cancel</button></li>
                                                                    <li className="Save_outer4"><button className="btn" type="submit">Save</button></li>
                                                                </ul>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                                <div className="tab-pane fade shadow rounded" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                    <div className="shawdow_outer profile_outer wp_profile_section privace_outer">
                                        <div className="inner56">
                                            <h4>Payment Information</h4>
                                        </div>

                                        {this.state.current_plans?.length > 0 && <div className="inner_tbale">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th className="legy6"><h5>Plan</h5></th>
                                                        <th className="legy6"><h5>Detailed Information</h5></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.current_plans.map((con, index) => {
                                                        // console.log(con);
                                                        return (
                                                            <tr>
                                                                  <td>
                                                                {
                                                                    !con.is_gift? 
                                                                    <div className="chatplan">
                                                                    <h2>{con.subscribe_plan__name}</h2>
                                                                    <h3>${con.total_paid_amount.toFixed(2)}</h3>
                                                                    {con.subscribe_plan__plan_time > 1? 
                                                                        this.state.allPlans.length > 0 && this.state.allPlans.filter(curr=>curr.parent_plan == con.subscribe_plan__id).length >0 ?
                                                                        (
                                                                          this.state.allPlans.length > 0 && this.state.allPlans.filter(curr=>curr.parent_plan == con.subscribe_plan__id)
                                                                          .map((curr) => (
                                                                            curr?.plans &&
                                                                            curr?.plans.length > 0 &&
                                                                            curr.plans.filter((curr) => curr.is_annual == false).map((curr) => (
                                                                                <p>Subscription Plan :<b> ${curr.amount?curr.amount : 0}</b></p>
                                                                              ))
                                                                            // <p>${curr.amount} Annual Subscription </p>
                                                                          ))
                                                                        ):"0":
                                                                        <p>Subscription Plan : <b> ${ con.price__amount}</b></p>
                                                                    }
                                                                    {
                                                                    con.subscribe_plan__plan_time > 1? 
                                                                        this.state.allPlans.length > 0 && this.state.allPlans.filter(curr=>curr.parent_plan == con.subscribe_plan__id).length >0 ?
                                                                        (
                                                                          this.state.allPlans.length > 0 && this.state.allPlans.filter(curr=>curr.parent_plan == con.subscribe_plan__id)
                                                                          .map((curr) => (
                                                                            curr?.plans &&
                                                                            curr?.plans.length > 0 &&
                                                                            curr.plans.filter((curr) => curr.is_annual == false)
                                                                            .map((curr) => (
                                                                                <p>Upfront Creation Fee :<b> ${con.total_paid_amount != 0 ? (con.total_paid_amount - curr.amount.toFixed(2)) : 0}</b></p>
                                                                              ))
                                                                            // <p>${curr.amount} Annual Subscription </p>
                                                                          ))
                                                                        ):"0"
                                                                        :
                                                                        <p>Upfront Creation Fee: <b>${con.total_paid_amount != 0 ? (con.total_paid_amount - con.price__amount).toFixed(2) : 0}</b></p>
                                                                    }
                                                                </div> :
// For Gifts
                                                                <div className="chatplan">
                                                                    {/* <h2>{con.subscribe_plan__name}</h2> */}
                                                                    <h3>Gift</h3>
                                                                </div>

                                                                }
                                                                </td>

                                                                <td>
                                                                    {
                                                                    !con.is_gift ?
                                                                    <div className="chatplan no_bou67">

                                                                    <p>Title of Eternaview: <b>{con.updated_details.title}</b></p>
                                                                    <p>Total Additional Recording Time Permitted: <b> {con.remaining_time ? con.remaining_time : con.subscribe_plan__total_time} hours</b></p>

                                                                    <p># of Edits that have been made during the current subscription period: <b>
                                                                        {(('initial' === con.updated_details.progress_status || 'in-progress' === con.updated_details.progress_status) && con.updated_details.no_of_edit == 0) || Object.keys(con.updated_details).length == 0 || (con.subscribe_plan__no_of_edit - con.updated_details.no_of_edit <= 0) ? 0 : ((con.subscribe_plan__no_of_edit + con.subscribe_plan__grace_no_of_edit) - (con.updated_details.no_of_edit + con.updated_details.grace_no_of_edit))}

                                                                    </b></p>

                                                                    <p># of Queries made by viewers during current subscription period: <b>
                                                                        {(('initial' === con.updated_details.progress_status || 'in-progress' === con.updated_details.progress_status) && con.updated_details.no_of_query == 0) || Object.keys(con.updated_details).length == 0 || (con.subscribe_plan__no_of_query - con.updated_details.no_of_query <= 0) ? 0 : ((con.subscribe_plan__no_of_query + con.subscribe_plan__grace_no_of_query) - (con.updated_details.no_of_query + con.updated_details.grace_no_of_query))
                                                                        }
                                                                    </b></p>

                                                                    <p className="custom_put6">Credit Card Information: <b>XXXX XXXX XXXX {con.subscription?.default_payment_method?.card?.last4}</b></p>
                                                                    <div className="list67">
                                                                        <p className="leg6">Purchased: {Moment(con.purchase_date).format('MM-DD-YYYY')}</p>
                                                                        {con.active_status == "active" && con.status == "paid" && <p className="leg6">Subscription Expiration Date (if not renewed): {con.exp_date_longterm?Moment(con.exp_date_longterm).format('MM-DD-YYYY'):Moment(con.expiry_date).format('MM-DD-YYYY')}</p>}

                                                                        {con.active_status == "inactive" || con.status == "pending" && <p className="leg6"> Payment status : Inactive/Pending</p>}
                                                                    </div>

                                                                    {con.active_status == "active" && con.status == "paid" && <div className="btn_outer99 upgrade_btn pla_ou80">
                                                                        {/* {con.updated_details.no_of_edit != 0 && Object.keys(con.updated_details).length > 0 && <ul className="cancel_subs_btns_div">
                                                                            {con.is_cancel == false && <li className="cancel">
                                                                                <button className="btn" onClick={() => this.handleCancelSubscription(con)}>Cancel Automatic Renewal</button>
                                                                            </li>}
                                                                            {con.is_cancel == true && <li className="cancel">
                                                                                <button className="btn" onClick={() => this.handleCancelSubscription(con)}>Turn Automatic Renewal Back On</button>
                                                                            </li>}
                                                                            {(con.remaining_edit == con.updated_details.no_of_edit || con.remaining_query == con.updated_details.no_of_query || con.remaining_time == 1 || con.updated_details.no_of_query == 0) && <li className="Save_outer4">
                                                                                <button type="button" className="btn" onClick={() => this.handleRenewSubscription(con)}>Renew Subscription Now</button>
                                                                            </li>}
                                                                        </ul>}

                                                                        {!con.updated_details.no_of_edit && Object.keys(con.updated_details).length == 0 && <ul className="cancel_subs_btns_div">
                                                                            {con.is_cancel == false && <li className="cancel">
                                                                                <button className="btn" onClick={() => this.handleCancelSubscription(con)}>Cancel Automatic Renewal</button>
                                                                            </li>}
                                                                            {con.is_cancel == true && <li className="cancel">
                                                                                <button className="btn" onClick={() => this.handleCancelSubscription(con)}>Turn Automatic Renewal Back On</button>
                                                                            </li>}

                                                                        </ul>} */}

                                                                        <ul className="cancel_subs_btns_div">
                                                                            {con.subscribe_plan__plan_time == 1 && con.is_cancel == false && <li className="cancel">
                                                                                <button className="btn" onClick={() => this.handleCancelSubscription(con)}>Cancel Automatic Renewal</button>
                                                                            </li>}
                                                                            {con.subscribe_plan__plan_time == 1 && con.is_cancel == true && <li className="cancel">
                                                                                <button className="btn" onClick={() => this.handleCancelSubscription(con)}>Turn Automatic Renewal Back On</button>
                                                                            </li>}
                                                                            {!Moment().isBefore(con.exp_date_longterm)&& con.subscribe_plan__plan_time >1 && <li className="Save_outer4">
                                                                                <button type="button" className="btn" onClick={() => this.renewSubscription(con)}>Renew Subscription Now</button>
                                                                            </li>}
                                                                            {con.updated_details.progress_status == 'complete' && ('undefined' !== typeof con.ninetyEdit || 'undefined' !== typeof con.ninetyQuery) && ((con.ninetyEdit >= (con.updated_details.no_of_edit + con.updated_details.grace_no_of_edit) || con.ninetyQuery >= (con.updated_details.no_of_query + con.updated_details.grace_no_of_query) || con.remaining_time == 1 || con.updated_details.no_of_query == 0)) && <li className="Save_outer4">
                                                                                <button type="button" className="btn" onClick={() => this.handleRenewSubscription(con)}>Renew Subscription Now</button>
                                                                            </li>}
                                                                        </ul>
                                                                        <button data-toggle="modal" data-target="#paymentMethod" onClick={() => this.paymentMethod(con)} className="Puchase_now_btn_new mt-4"> Update Payment Method</button>
                                                                    </div>}
                                                                </div>
                                                                :

                                                                <div className="chatplan no_bou67">

                                                                <p>Title of Eternaview: <b>{con.updated_details.title}</b></p>
                                                                <p>Total Additional Recording Time Permitted: <b> {con.remaining_time ? con.remaining_time : con.subscribe_plan__total_time} hours</b></p>

                                                                <p># of Edits that have been made during the current subscription period: <b>
                                                                    {(('initial' === con.updated_details.progress_status || 'in-progress' === con.updated_details.progress_status) && con.updated_details.no_of_edit == 0) || Object.keys(con.updated_details).length == 0 || (con.subscribe_plan__no_of_edit - con.updated_details.no_of_edit <= 0) ? 0 : ((con.subscribe_plan__no_of_edit + con.subscribe_plan__grace_no_of_edit) - (con.updated_details.no_of_edit + con.updated_details.grace_no_of_edit))}

                                                                </b></p>

                                                                <p># of Queries made by viewers during current subscription period: <b>
                                                                    {(('initial' === con.updated_details.progress_status || 'in-progress' === con.updated_details.progress_status) && con.updated_details.no_of_query == 0) || Object.keys(con.updated_details).length == 0 || (con.subscribe_plan__no_of_query - con.updated_details.no_of_query <= 0) ? 0 : ((con.subscribe_plan__no_of_query + con.subscribe_plan__grace_no_of_query) - (con.updated_details.no_of_query + con.updated_details.grace_no_of_query))
                                                                    }
                                                                </b></p>

                                                                <p className="custom_put6">Credit Card Information: <b>XXXX XXXX XXXX XXXX</b></p>
                                                                <div className="list67">
                                                                    <p className="leg6">Subscription Start Date: {Moment(con.purchase_date).format('MM-DD-YYYY')}</p><br/>
                                                                    {con.active_status == "active" && con.status == "paid" && <p className="leg6">Subscription Expiration Date (if not renewed): {con.exp_date_longterm?Moment(con.exp_date_longterm).format('MM-DD-YYYY'):Moment(con.expiry_date).format('MM-DD-YYYY')}</p>}

                                                                    {con.active_status == "inactive" || con.status == "pending" && <p className="leg6"> Payment status : Inactive/Pending</p>}
                                                                </div>

                                                                {con.active_status == "active" && con.status == "paid" && <div className="btn_outer99 upgrade_btn pla_ou80">
                                                                    <ul className="cancel_subs_btns_div">
                                                                        {/* {con.subscribe_plan__plan_time == 1 && con.is_cancel == false && <li className="cancel">
                                                                            <button className="btn" onClick={() => this.handleCancelSubscription(con)}>Cancel Automatic Renewal</button>
                                                                        </li>} */}
                                                                        {con.subscribe_plan__plan_time == 1 && con.is_cancel == true && <li className="cancel">
                                                                            <button className="btn" onClick={() => this.handleCancelSubscription(con)}>Turn Automatic On</button>
                                                                        </li>}
                                                                        {!Moment().isBefore(con.exp_date_longterm)&& con.subscribe_plan__plan_time >1 && <li className="Save_outer4">
                                                                            <button type="button" className="btn" onClick={() => this.renewSubscription(con)}>Renew Subscription Now</button>
                                                                        </li>}
                                                                        {con.updated_details.progress_status == 'complete' && ('undefined' !== typeof con.ninetyEdit || 'undefined' !== typeof con.ninetyQuery) && ((con.ninetyEdit >= (con.updated_details.no_of_edit + con.updated_details.grace_no_of_edit) || con.ninetyQuery >= (con.updated_details.no_of_query + con.updated_details.grace_no_of_query) || con.remaining_time == 1 || con.updated_details.no_of_query == 0)) && <li className="Save_outer4">
                                                                            <button type="button" className="btn" onClick={() => this.handleRenewSubscription(con)}>Renew Subscription Now</button>
                                                                        </li>}
                                                                    </ul>
                                                                    <button data-toggle="modal" data-target="#paymentMethod" onClick={() => this.paymentMethod(con)} className="Puchase_now_btn_new mt-4"> Update Payment Method</button>
                                                                </div>}
                                                            </div>


                                                                    }
                                                 
                                                                </td>
                                                            </tr>
                                                            );
                                                    })}

                                                </tbody>
                                            </table>
                                            <Elements stripe={stripePromise}>
                                                <Modal show={this.state.showModal} className="modal">
                                                 <CheckoutForm saveForm = {this.savePaymentMethod} closePayment = {()=>{this.resetPaymentMethod()}} />
                                                </Modal>
                                            </Elements>

                                           

                                        </div>}

                                        {this.state.current_plans?.length == 0 && <div className="table-responsive table_outer">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <p>There is no subscription plan bought yet.</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>}

                                        <div className="modal custom_logoutmodal" id="cancelSubscriptionModal">
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h4 className="modal-title">Please Confirm</h4>
                                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                                    </div>
                                                    <div className="modal-body custom_body custom_confirm">
                                                        <div className="inner_tex45">
                                                            <h4>Are you sure you want to turn off automatic renewal?
                                                            </h4>
                                                            <p>Note: If you turn off automatic renewal, your Eternaview will no longer be accessible after the subscription expires.
                                                            </p>
                                                            <div className="btn_outer99">
                                                                <ul>
                                                                    <li className="cancel"><button data-dismiss="modal" className="btn">No</button></li>
                                                                    <li className="Save_outer4" data-dismiss="modal" onClick={this.handleCancelSubscription}><button className="btn">Yes</button></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="modal custom_logoutmodal" id="turnOnSubscriptionModal">
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h4 className="modal-title">Please Confirm</h4>
                                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                                    </div>
                                                    <div className="modal-body custom_body custom_confirm">
                                                        <div className="inner_tex45">
                                                            <h4>Are you sure you want to turn on automatic renewal subscription now?
                                                            </h4>
                                                            <div className="btn_outer99">
                                                                <ul>
                                                                    <li className="cancel"><button data-dismiss="modal" className="btn">No</button></li>
                                                                    <li className="Save_outer4" data-dismiss="modal" onClick={this.handleCancelSubscription}><button className="btn">Yes</button></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="tab-pane fade shadow rounded" id="v-pills-privacy" role="tabpanel" aria-labelledby="v-pills-privacy-tab">
                                    <div className="shawdow_outer profile_outer wp_profile_section">
                                        <div className="inner56">
                                            <h4>Privacy Settings</h4>
                                        </div>
                                        <div className="privacy_text">
                                            <div className="profile_user">
                                                <p>Allow other creators to know when you have viewed their Eternaview:</p>
                                                <div className="checkouter56"><label className="container9">Yes<input type="radio" onChange={this.handleChange} checked={(allowed_creator_viewed == true || allowed_creator_viewed == 'true') ? true : false} name="allowed_creator_viewed" value='true' /><span className="checkmark"></span></label><label className="container9">No<input type="radio" name="allowed_creator_viewed" checked={(allowed_creator_viewed == false || allowed_creator_viewed == 'false') ? true : false} onChange={this.handleChange} value='false' /><span className="checkmark"></span></label></div>
                                            </div>
                                            <div className="profile_user">
                                                <p>Allow third parties to view your anonymized data for purposes of consumer research, advertising, academic research and all other purposes:</p>
                                                <div className="checkouter56"><label className="container9">Yes<input type="radio" onChange={this.handleChange} value='true' checked={(allowed_third_party_viewed == true || allowed_third_party_viewed == 'true') ? true : false} name="allowed_third_party_viewed" /><span className="checkmark"></span></label><label className="container9">No<input type="radio" checked={(allowed_third_party_viewed == false || allowed_third_party_viewed == 'false') ? true : false} name="allowed_third_party_viewed" onChange={this.handleChange} value='false' /><span className="checkmark"></span></label></div>
                                            </div>
                                            <div className="profile_user">
                                                <p>Please keep me updated on Eternaview news, events, and offers:</p>
                                                <div className="checkouter56"><label className="container9">Yes<input type="radio" onChange={this.handleChange} value='true' checked={(keep_updated == true || keep_updated == 'true') ? true : false} name="keep_updated" /><span className="checkmark"></span></label><label className="container9">No<input type="radio" name="keep_updated" checked={(keep_updated == false || keep_updated == 'false') ? true : false} onChange={this.handleChange} value='false' /><span className="checkmark"></span></label></div>
                                            </div>
                                            <div className="profile_user">
                                                <p>We - the folks behind Eternaview - reserve all rights to monitor all recordings put on our site for conformity to our standards for hate speech, violence, nudity, etc. However, we also ask you to grant us to permission to view your Eternaview for our own research purposes and to optimize viewers' potential interaction when speaking to it:</p>
                                                <div className="checkouter56"><label className="container9">Yes<input type="radio" onChange={this.handleChange} value='true' checked={(folks_behind == true || folks_behind == 'true') ? true : false} name="folks_behind" /><span className="checkmark"></span></label><label className="container9">No<input type="radio" name="folks_behind" checked={(folks_behind == false || folks_behind == 'false') ? true : false} onChange={this.handleChange} value='false' /><span className="checkmark"></span></label></div>
                                            </div>
                                            <div className="btn_outer99 custom_b56">
                                                <ul>
                                                    {
                                                        this.state.changeMade ?
                                                            (<li style={{ display: "block" }} className="privacySaveBtn Save_outer4"><button type="button" className="btn" onClick={this.submitPrivacy}>Submit</button></li>)
                                                            : <li style={{ display: "none" }} className="privacySaveBtn Save_outer4"><button type="button" className="btn" onClick={this.submitPrivacy}>Submit</button></li>

                                                    }

                                                </ul>
                                            </div>
                                            {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#privacymodal">
                                            Privacy Popup
                                            </button> */}
                                            <div class="modal fade" id="privacymodal" tabindex="-1" role="dialog" aria-labelledby="privacymodalLabel" aria-hidden="true">
                                                <div class="modal-dialog" role="document">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title" id="exampleModalLabel">Privacy Setting</h5>
                                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </div>
                                                        <div class="modal-body privacy_modal_body">
                                                            <div className="privacypopup_title">
                                                                Would you like to save your changes?
                                                            </div>
                                                        </div>
                                                        <div class="modal-footer privacy_modal_footer">
                                                            <button type="button" class="btn btn-secondary">Yes</button>
                                                            <button type="button" class="btn custom_no" data-dismiss="modal" aria-label="Close">No</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade shadow rounded" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                    <div className="shawdow_outer profile_outer wp_profile_section">
                                        <div className="inner56">
                                        <h4>My Eternaviews
                                            {views.length > 0 && views[0].is_show == false && <span className="deactivate_eternatext"> Your eternaview has been deactivated <button className='request-activate' onClick={this.requestActivation}>Request Activation</button></span>}
                                        </h4>
                                        </div>
                                        <div className="custom67 ente45 custom_proiy5">
                                            <div className="row specinf_bottom">
                                                {views.map((con, index) => {
                                                    return (<div className="col-lg-4 col-md-12">
                                                        <div className="small_box1 eterna_thumbnail">
                                                            <div className={con.is_show == false ? "overlay_disable" : ""}></div>

                                                            {con.progress_status == "complete" && <div className="top_texr5">
                                                                {con.edit == false && <h5 onClick={() => this.editEternaTitle(con)}>{con.title}</h5>}
                                                                {con.edit == true && <div className="input_iin67"><input type="text" name={`${con.name}`} value={`${con.value}`} onChange={this.onInputEternachange} /></div>}

                                                                {con.edit == true && <span className='update-title' onClick={() => this.submitTitle(con)}><i className="fa fa-check"></i></span>}

                                                                <select value={con.permission} onChange={this.changePermission}>
                                                                    <option value={`private+${con.id}`}>Private</option>
                                                                    <option value={`public+${con.id}`}>Public</option>
                                                                </select>
                                                                {/* value={`${this.state.permission}${con.permission}+${con.id}`} */}
                                                            </div>}

                                                            {(con.progress_status == "initial" || con.progress_status == "in-progress") && <div className="top_texr5">
                                                                <Link to={`/create/eternaview/${con.category_id}+${con.sub_category_id}+${con.type == "video" ? '0' : '1'}+${con.id}`}><h5>{con.title}</h5></Link>
                                                            </div>}

                                                            <div className="top_texr56">
                                                                <p>Download :<DownloadEterna id={con.id} token={this.props.auth.loginUserToken} /></p><br></br>
                                                                <p>Created: {Moment(con.created_on).format('MM-DD-YYYY')}</p>
                                                                <span className="last_inner">Last Edited: {Moment(con.updated_on).format('MM-DD-YYYY')}</span>
                                                            </div>
                                                            {con.progress_status == "complete" && <div className="edit_name tooltip_edit_name" hover-tooltip="Click to edit your eternaview." tooltip-position="top"><Link to={`/create/congratulations/${con.category_id}+${con.sub_category_id}+${con.type == "video" ? '0' : '1'}+view-${con.id}`}><img src={material_edit} /></Link></div>}
                                                            {/* <img src={icon_material}  /> data-toggle="modal" data-target="#myModal4" */}
                                                            <div className="modal custom_modal4" id="myModal4">
                                                                <div className="modal-dialog">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                                                                            <h4 className="modal-title">Please Confirm</h4>
                                                                        </div>
                                                                        <div className="modal-body custom_body custom_confirm">
                                                                            <div className="inner_tex45">
                                                                                <h4>Are you sure you want to remove this person’s<br />
                                                                                    permission to watch your Eternaview?
                                                                                </h4>
                                                                                <p>Note: Their access may not be restricted if a link to your<br />
                                                                                    Eternaview was separately shared with them
                                                                                </p>
                                                                                <div className="btn_outer99">
                                                                                    <ul>
                                                                                        <li className="cancel"><button className="btn" data-dismiss="modal" data-toggle="modal" data-target="#myModal2" onClick={() => this.checkModalId(con)}>No</button></li>
                                                                                        <li className="Save_outer4"><button className="btn" onClick={() => this.removePermission()} data-dismiss="modal">Yes</button></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="img_i76">
                                                                {(con.progress_status == "initial" || con.progress_status == "in-progress") && <a href={`/create/eternaview/${con.category_id}+${con.sub_category_id}+${con.type == "video" ? '0' : '1'}+${con.id}`} className="resumecreate">Resume Creating Eternaview!</a>}




                                                                {(() => {
                                                                    if (con.type == "video" && (con.progress_status == "initial" || con.progress_status == "in-progress")) {
                                                                        return (
                                                                            <img src={video_img} />
                                                                        )
                                                                    } else if ((con.type == "video" && con.nodding_video == '')) {
                                                                        return (
                                                                            <img src={video_img} />
                                                                        )
                                                                    } else if (con.type == "video") {
                                                                        return (
                                                                            <video id="myVideo" className="video-js vjs-default-skin interact-public" ref={node => con.viewEternaNode = node} options={con.videoJsOptions} loop></video>
                                                                        )
                                                                    }
                                                                    else {

                                                                    }
                                                                })()}


                                                                {(() => {
                                                                    if (con.type == "audio" && (con.progress_status == "initial" || con.progress_status == "in-progress")) {
                                                                        return (
                                                                            <img src={audio_img} />
                                                                        )
                                                                    } else if ((con.type == "audio" && con.author__avatar == '')) {
                                                                        return (
                                                                            <img src={audio_img} />
                                                                        )
                                                                    } else if (con.type == "audio") {
                                                                        return (
                                                                            <img src={con.author__avatar} />
                                                                        )
                                                                    }
                                                                })()}





                                                                {/*                                                                 
                                                                {(con.type == "video" && con.nodding_video == '') && <img src={vector_audio} />}
                                                                {(con.type == "video" && con.nodding_video != "") && <video id="myVideo" className="video-js vjs-default-skin interact-public" ref={node => con.viewEternaNode = node} options={con.videoJsOptions} loop></video>}
                                                                {(con.type == "audio" && con.author__avatar == "") && <img src={vector_audio} />}
                                                                {(con.type == "audio" && con.author__avatar != "") && <img src={con.author__avatar} />} */}
                                                            </div>

                                                            <div className="bottom_icon">
                                                                <ul>
                                                                    {con.progress_status == "complete" && <li hover-tooltip="Click to share your eternaview." tooltip-position="top">
                                                                        {con.is_show == true && <img src={user_add} data-toggle="modal" data-target="#myModal2" onClick={() => this.checkModalId(con)} title="Click to share your eternaview." data-placement="top" />}

                                                                        {con.is_show == false && <img src={user_add} title="Click to share your eternaview." data-toggle="tooltip" data-placement="top" />}

                                                                        <p>{con.invitees_count}</p>
                                                                    </li>}
                                                                    <li className="cutom7">
                                                                        <div className="modal custom_model12" id="myModal2">
                                                                            <div className="modal-dialog">
                                                                                <div className="modal-content">
                                                                                    <div className="modal-header">
                                                                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                                                                        <div className="custom8">
                                                                                            <button className="btn"
                                                                                                data-toggle="modal" data-target="#myModal3" data-dismiss="modal">Invite People</button>
                                                                                        </div>

                                                                                        <h4 className="modal-title">Invites Sent</h4>

                                                                                    </div>
                                                                                    <div className="modal-body custom_body">

                                                                                        {inviteeLists.map((inv, index) => {
                                                                                            return (
                                                                                                <div className="main_outer4">
                                                                                                    {(inv.invited_user__avatar == "" || inv.invited_user__avatar == null) && <img src={user_default} />}
                                                                                                    {/* {inv.invited_user__avatar != "" && <img src={inv.invited_user__avatar} />} */}
                                                                                                    <div className="inner4">
                                                                                                        <h5>{inv.name}</h5>
                                                                                                        <p>{inv.email}</p>
                                                                                                    </div>
                                                                                                    <div className="hand_outer">
                                                                                                        <a href="#" className="uninvite_btn"
                                                                                                            onClick={() => this.revokeModal(inv.email)}
                                                                                                            aria-hidden="true" data-toggle="modal" data-target="#myModal4" data-dismiss="modal">Uninvite</a>
                                                                                                    </div>
                                                                                                </div>)
                                                                                        })
                                                                                        }
                                                                                    </div>

                                                                                    <div className="custom_footer">
                                                                                        <div className="btn_outer99">
                                                                                            <ul>
                                                                                                <li className="Save_outer4">
                                                                                                    <input type="hidden" value={con.shared_links} />
                                                                                                    <button className="btn" type="button" onClick={() => this.copyToClipboard(con.shared_links)} > Copy Link</button>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                        <div className="lik_outer">
                                                                                            <p>{con.shared_links}</p>

                                                                                            <h4>This link will work for:</h4>
                                                                                            <p>Anyone who it is shared with</p>
                                                                                            <input type="hidden" name="access_permission" value="any_one_with_link" />
                                                                                            {/* <select name="access_permission" value={this.state.access_permission} onChange={this.onInputchange}>
                                                                                                <option value="any_one_with_link">Anyone who it is shared with</option>
                                                                                                <option value="restricted">Only those people who you have also sent an invite</option>
                                                                                            </select> */}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        {con.progress_status == "complete" && <Link to={`/home/interact-one/${con.id}/${con.author}/${con.permission}`}><li className="cutom7" hover-tooltip="Click to interact." tooltip-position="top"><img src={play_button} title="Click to interact." data-toggle="tooltip" data-placement="top" /></li></Link>}

                                                                        <div className="modal" id="myModal3">
                                                                            <div className="modal-dialog">
                                                                                <div className="modal-content">
                                                                                    <div className="modal-header">
                                                                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                                                                        <h4 className="modal-title">Invite Viewers to Your Eternaview</h4>
                                                                                    </div>
                                                                                    <div className="modal-body custom_body">
                                                                                        <div className="form_outer99">
                                                                                            <div className="input_outer"><label>Name<span>Optional</span></label><input type="text" name="invite_name" value={this.state.invite_name} onChange={this.onInputchange} /></div>
                                                                                            <div className="input_outer"><label>Email Address*</label><input type="text" name="invite_email" value={this.state.invite_email} onChange={this.onInputchange} /></div>
                                                                                            <input type="hidden" name="view_id" value={this.state.view_id} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="custom_footer no_border54">
                                                                                        <div className="btn_outer99">
                                                                                            <ul>
                                                                                                {this.state.invite_email == '' && <li className="Save_outer4"><button type="submit" className="btn" onClick={this.sendInvite}>Invite</button></li>}

                                                                                                {this.state.invite_email != '' && <li className="Save_outer4"><button type="submit" className="btn" onClick={this.sendInvite} data-dismiss="modal">Invite</button></li>}
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    {con.progress_status == "complete" && <li className="cutom78" hover-tooltip="List of views & likes." tooltip-position="top">
                                                                        {con.is_show == true && <img src={icon_eyes} data-target="#myModal" onClick={() => this.getAllViewers(con.id)} data-toggle="tooltip" data-placement="top" title="List of views & likes." />}
                                                                        {con.is_show == false && <img src={icon_eyes} title="List of views & likes." data-toggle="tooltip" data-placement="top" />}
                                                                        <p>{con.viewer_count}</p>
                                                                    </li>}

                                                                    <div className="modal fade custom_modale" id="myModal" role="dialog">
                                                                        <div className="modal-dialog">
                                                                            <div className="modal-content">
                                                                                <div className="modal-header">
                                                                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                                                                    <h4 className="modal-title">My Viewers</h4>
                                                                                </div>
                                                                                <div className="modal-body custom_body">
                                                                                    <p className="right_text">Total Likes: {this.state.viewerLikes}</p>
                                                                                    {
                                                                                        viewersList && viewersList.map((view, index) => {
                                                                                            return (
                                                                                                <div className="main_outer4">
                                                                                                    <img src={user_default} />
                                                                                                    <div className="inner4">
                                                                                                        <h5>{view.user != null || view.email != null ? view.user : "Anonymous Users"}</h5>
                                                                                                        {view.user != null && view.email != null ? <p>{view.email}</p> : <h5>{view.email}</h5>}
                                                                                                    </div>
                                                                                                    <div className={view.is_liked == "yes" ? "hand_outer" : "hand_outer thumb_grey"}>
                                                                                                        <i className="fa fa-thumbs-up" aria-hidden="true"></i>
                                                                                                    </div>
                                                                                                </div>)
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>)
                                                })}
                                            </div>

                                            {views.length == 0 && <div className="specinf_bottom">
                                                <p>You have not created any Eternaviews yet. If you would like create one, you can click here now. <Link to="/home/create/"><button className="btn">Create Eternaview!</button></Link> </p>
                                            </div>}
                                        </div>

                                        {this.props.auth.loginUserToken != '' && <div className="custom67 ente45 custom_proiy5">

                                            <div className="enta_oter custom_entrna">
                                                <h2>Practice Eternaview</h2>
                                            </div>
                                            {(practiceViews && practiceViews != '') && <div className="row specinf_bottom">
                                                {/* {practiceViews.map((con, index) => { 
                                                    return ( */}
                                                <div className="col-lg-4 col-md-12">
                                                    <div className="small_box1 ete_small_box">
                                                        <div className="top_text_outer">
                                                            <div className="top_texr5">
                                                                <h5>{practiceViews.title}</h5>

                                                            </div>
                                                            <div className="top_texr56">
                                                                <p>Created: {Moment(practiceViews.created_on).format('MM-DD-YYYY')}</p>
                                                                {/* <span className="last_inner">Last Edited: {Moment(con.updated_on).format('MM-DD-YYYY')}</span> */}
                                                            </div>
                                                        </div>

                                                        <div className="img_i76 practice_eterna_img">
                                                            <a href={`/create/eternaview/${practiceViews.category_id}+${practiceViews.sub_category_id}+${practiceViews.type == "video" ? '0' : '1'}+${practiceViews.id}`} className="resumecreate">Resume Creating Eternaview!</a>
                                                            {practiceViews.nodding_video == '' && <img src={vector_audio} />}
                                                            {/* {con.nodding_video != "" && <video src={con.nodding_video} autoPlay loop muted></video>} */}

                                                            {practiceViews.nodding_video != "" && <video id="practVideo" className="video-js vjs-default-skin interact-public" ref={node => this.practNode = node} options={this.videoJsOptions1} loop></video>}
                                                            <div className="bottom_icon">
                                                                <ul>
                                                                    <li></li>
                                                                    <li><Link to={`/home/practice-interact/`}><li className="cutom7" hover-tooltip="Click to interact." tooltip-position="top"><img src={play_button} /></li></Link></li>
                                                                    <li className="cutom78">
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                {/* );
                            })} */}

                                            </div>}

                                            {(practiceViews == '') && <div className="specinf_bottom">
                                                <p>You have not created any practice Eternaview yet. If you would like create one, you can click here now. <Link to="/create/practice-test/"><button className="btn">Practice Eternaview!</button></Link> </p>
                                            </div>}

                                        </div>}

                                        {/* <div className="pagntion_outer">
                                            <ul className="pagination pagination-lg">
                                                <li className="page-item"><a className="page-link" href="#" aria-label="Previous"><i className="fa fa-angle-left" aria-hidden="true"></i></a></li>
                                                <li className="page-item"><a className="page-link" href="#">1</a></li>
                                                <li className="page-item active"><a className="page-link " href="#">2</a></li>
                                                <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                <li className="page-item"><a className="page-link" href="#">4</a></li>
                                                <li className="page-item"><a className="page-link" href="#">5</a></li>
                                                <li className="page-item"><a className="page-link" href="#">6</a></li>
                                                <li className="page-item"><a className="page-link" href="#">7</a></li>
                                                <li className="page-item"><a className="page-link" href="#">8</a></li>
                                                <li className="page-item"><a className="page-link" href="#">9</a></li>
                                                <li className="page-item"><a className="page-link" href="#" aria-label="Next"><i className="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                            </ul>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="tab-pane fade shadow rounded" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                    <div className="tab-pane fade shadow rounded p-5  show active" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                        {/* <div className="shawdow_outer profile_outer wp_profile_section">
                                            <div className="inner56">
                                                <h4>Shared with me</h4>
                                            </div>

                                            {sharedWithMe.length > 0 &&
                                                sharedWithMe && sharedWithMe.map((shr, index) => {
                                                    return (<div className="row full_width custom_sope ctr45">
                                                        <div className="col-lg-8 col-md-8">
                                                            <div className="main_outer4 custom_inner45">
                                                                <img className="rec_img" src={user_default} />
                                                                <div className="inner4">
                                                                    <h5>{shr.title}</h5>
                                                                    <p>{shr.category__name}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4">
                                                                <div className="custom_P">
                                                                    <p>Date Created<br />{Moment(shr.created_on).format('MM-DD-YYYY')}</p>
                                                                </div>
                                                            </div>
                                                        </div></div>)
                                                })
                                            }

                                        </div> */}

                                        <section className="custom67 vid67 shared_with_me">
                                            <div className="shawdow_outer profile_outer wp_profile_section">
                                                <div className="inner56">
                                                    <h4>Shared with Me</h4>
                                                    {/* <p>Check invites you've received to view Eternaviews created by others.</p> */}
                                                </div>
                                                {sharedWithMe.length == 0 && <div className="full_width custom_sope ctr45 remove_paddind">
                                                    <p><b>Sorry, you have not yet been invited to view any Eternaviews created by others. You can, however, view some samples or public ones on the "Interact" screen.</b></p>
                                                </div>}


                                                {sharedWithMe.length > 0 &&
                                                    sharedWithMe && sharedWithMe.map((shr, index) => {
                                                        return (<div className="row full_width custom_sope ctr45 align-items-center">
                                                            <div className="col-lg-8 col-md-8">
                                                                <div className="main_outer4 custom_inner45">
                                                                    {shr.trans_coded_url == "" && <img className="rec_img" src={user_default} />}

                                                                    {(shr.views_type == "audio" && shr.trans_coded_url != "") && <audio src={shr.trans_coded_url} controls></audio>}

                                                                    {(shr.views_type == "video" && shr.trans_coded_url != "") && <video id="myVideo" className="video-js vjs-default-skin rec_img videoshared" ref={node => shr.viewShared = node} options={shr.videoJsOptions}></video>}

                                                                    <div className="inner4">
                                                                        <h5>{shr.title}</h5>
                                                                        <p>{shr.category__name}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4">
                                                                <div className="custom_P">
                                                                    <p>Date Created<br />{Moment(shr.created_on).format('MM-DD-YYYY')}</p>
                                                                </div>
                                                            </div>
                                                        </div>)
                                                    })
                                                }

                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => ({ auth: state.auth });
const mapDispatchToProps = dispatch => ({
    profile: bindActionCreators(profile, dispatch),
    getEternaVideos: bindActionCreators(getEternaVideos, dispatch),
    getPlans:bindActionCreators(getPlans,dispatch),
    updateProfile: bindActionCreators(updateProfile, dispatch),
    getCountries: bindActionCreators(getCountries, dispatch),
    changePassword: bindActionCreators(changePassword, dispatch),
    logOut: bindActionCreators(logOut, dispatch),
    myEternaviewList: bindActionCreators(myEternaviewList, dispatch),
    updateEternaviewPermission: bindActionCreators(updateEternaviewPermission, dispatch),
    sendInviteToView: bindActionCreators(sendInviteToView, dispatch),
    listInviteUsers: bindActionCreators(listInviteUsers, dispatch),
    revokePermissionView: bindActionCreators(revokePermissionView, dispatch),
    inviteSharedWithMe: bindActionCreators(inviteSharedWithMe, dispatch),
    privacySettings: bindActionCreators(privacySettings, dispatch),
    viewersList: bindActionCreators(viewersList, dispatch),
    postPrivacyForm: bindActionCreators(postPrivacyForm, dispatch),
    getPracticeEternaviews: bindActionCreators(getPracticeEternaviews, dispatch),
    getSubscriptionDetail: bindActionCreators(getSubscriptionDetail, dispatch),
    cancelSubscription: bindActionCreators(cancelSubscription, dispatch),
    requestReactivation: bindActionCreators(requestReactivation, dispatch),
    renewSubscription: bindActionCreators(renewSubscription, dispatch),
    editTitle: bindActionCreators(editTitle, dispatch),
    createCheckoutSession:bindActionCreators(createCheckoutSession,dispatch),
    verifyUserSubscription: bindActionCreators(verifyUserSubscription, dispatch),
    updatePaymentMethod: bindActionCreators(updatePaymentMethod, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Profile));