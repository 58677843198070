import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import thankyou_icon from "../../Images/thnku-icon.png";
import thankyou_text from "../../Images/thnku-text.png";
import FacebookPixel from '../../MetaComponents/Facebookpixel';
import { sendGiftEmailToUser,subscribeUserTogift } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import connection from '../../config/connection';
function GiftThankyou(props) {
    const auth = useSelector((state) => state);
    const dispatch = useDispatch()
    const recipentEmail = sessionStorage.getItem("giftRecipent")
    const handleDefaultBehaviour = () =>{
        const subscriptionPayload =JSON.parse(sessionStorage.getItem("subscribeObj")) 
        if(subscriptionPayload){
            subscriptionPayload.recipient_email = recipentEmail
        }
        let token = false
        if(auth.auth.loginUserToken != "" || sessionStorage.getItem("token")) {
            token = true 
        }
     
        dispatch(subscribeUserTogift(subscriptionPayload,token, (res) => {
            sessionStorage.removeItem("subscribeObj")
          }));
        dispatch(sendGiftEmailToUser({"recipient_email":recipentEmail}, (res) => {
            sessionStorage.removeItem("giftRecipent")
          }));
    }

    useEffect(()=>{
        handleDefaultBehaviour()
    },[])
   

    return (
        <>
   <FacebookPixel trackName="Purchase" obj={{value: 60, currency: "USD"}}/>
        <section class="thankyou-outer">
            <div class="container inner-container">
                <div class="row">
                    <div class="col-md-8 offset-md-2 col-sm-12">
                        <div class="login-inner thankyou-outer">
                            <div class="thankyou-upper">
                                <img src={thankyou_icon} alt="" />
                            </div>
                            <div class="thankyou-bottom">
                                <img src={thankyou_text} alt="" />
                                <p class="p-text">Your payment has been received. We hope the recipient likes their gift.
                                {/*  The subscription starts on the day you indicated to send the gift notification. */}
                                </p>
                                {/* <p class="p-text">Your payment has been received. You may now move on to recording.</p> */}
                            <Link      style={{ cursor: "pointer" }}  onClick={()=>{window.location.href =auth.auth.loginUserToken ? connection.homePage + `?access_token=${auth.auth.loginUserToken}` : connection.homePage}}>
                                <p      style={{ cursor: "pointer" }} className="new_user5">
                                   <a
                                    className="Puchase_now_btn_new"
                                    style={{ cursor: "pointer" }}
                                    >
                                    Continue 
                                   </a>
                                </p>
                            </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
};

export default GiftThankyou;
