import React, { useEffect } from "react";
import { Circles } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../redux/actions";
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import connection from "../config/connection";
const Logout = (props) => {
  const auth = useSelector((state) => state);
  const history = useHistory()
  const dispatch = useDispatch();
  const logout = () => {
    let payload = {
      grant_type: "password",
      client_id: auth.auth.credentials.client_id,
      client_secret: auth.auth.credentials.client_secret,
      token: auth.auth.loginUserToken,
    };

    dispatch(
      logOut(payload, (result) => {
        if (result.status == true) {
          toast("You are successfully logged out of application.", {
            position: "top-right",
            autoClose: 3000,
            type: "success",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          localStorage.clear()
          sessionStorage.clear();
          window.location.href = connection.homePage
          // history.push("/oauth/sign-in/");
        }
      })
    );
  };

  useEffect(()=>{
    logout()
  },[])

  return <Circles wrapperClass="circle-spinner"/>;
};

export default Logout;
