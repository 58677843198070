import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import google from '../Images/google.png'
import {
    LoginSocialGoogle,
  } from 'reactjs-social-login'
import GoogleLogin from "react-google-login";
import Elogo from '../Images/wp_images/Eternaview-Logo1.png';
import FacebookLogin from "react-facebook-login";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import edit from "../Images/edit.png";
import { Button, Form } from "reactstrap";
import {
    register,
    getCredentials,
    registerSocialLogin,
    login,
    checkEternaviewCreated,
    setNavigationBanner
} from "../redux/actions/index";
import Loader from "react-loader";
import CustomFaceBookLogin from '../pages/auth/CustomFaceBookLogin';
import connection from "../config/connection";

function LoginComponent(props) {
    const dispatch = useDispatch();

    let [email, setEmail] = useState('')
    let [emailError, setEmailError] = useState('')
    let [password, setPassword] = useState('')
    let [passwordError, setPasswordError] = useState('')
    let [inputtype, setInputType] = useState(false);
    let [loaded, setLoaded] = useState(true);
    const [clickedFb, setClickedFb] = useState(false);
    const auth = useSelector(state => state);

    useEffect(() => {
        /*  if (auth.auth.loginUserToken != "") {
             props.history.push('/');
         } */
       //  alert(props.isLoginPopup);

         if(props.isLoginPopup==false)
         {
            if (auth.auth.loginUserToken != "") {
                // props.history.push('/');
                
                window.location.href = connection.homePage + `?access_token=${auth.auth.loginUserToken}`
            }
         }
         else 
         {
            console.log('props.loginRedirectUrl');
            console.log(props);
         }
        setInputType(false);
        setLoaded(true);
    }, []);

    const updateBannerDetails = (access_token) =>{
        dispatch(setNavigationBanner( {is_banner:true},access_token,(res)=>{
        }))
      }

    function showPassword() {
        setInputType(!inputtype);
    };

    function handleEmailInput(event) {
        setEmail(event.target.value)
        setEmailError(validate('email', event.target.value))
    };

    function handlePasswordInput(event) {
        setPassword(event.target.value)
        setPasswordError(validate('password', event.target.value))
    }

    function validate(name, value) {
        switch (name) {
            case "email":
                if (!value || value.trim() === "") {
                    return "Email is required.";
                } else {
                    return "";
                }
            case "password":
                if (!value || value.trim() === "") {
                    return "Password is required.";
                } else {
                    return "";
                }
            default: {
                return "";
            }
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setLoaded(false);
        dispatch(getCredentials((res) => {
            if (res.status == true) {
                let payload = {
                    "username": email,
                    "password": password,
                    "grant_type": "password",
                    "client_id": res.res.client_id,
                    "client_secret": res.res.client_secret
                }
                dispatch(login(payload, (result) => {
                    setLoaded(true);
                    if (result.error == "invalid_grant") {
                        toast('Your email or password is incorrect. Please click the “forgot password” link if you would like reset your password or “Register Now” if you haven’t registered yet.', {
                            position: "top-right",
                            autoClose: 10000,
                            type: 'error',
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                        });

                        return;
                    } else if (result.access_token) {
                        dispatch(checkEternaviewCreated(result.access_token, (result) => {
                            console.log(result);
                        }));

                        toast('You are successfully logged into the application.', {
                            position: "top-right",
                            autoClose: 10000,
                            type: 'success',
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                        });
                        updateBannerDetails(result.access_token)
                        sessionStorage.setItem("token",result.access_token)

                       // props.history.push('/');
                        props.closeLoginPopup(false);
                    } else {
                        toast(result.error, {
                            position: "top-right",
                            autoClose: 10000,
                            type: 'error',
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                        });

                        return;
                    }
                }));
            }
        }));

    };

    const responseGoogle = (response) => {
        if (!response.error) {
            console.log('Logged In successfully with Google');
        } else {
            return;
        }

        setLoaded(false);
        const googleresponse = {
            Name: response.data.name,
            email: response.data.email,
            token: response.data.sub,
            Image: response.data.picture,
            ProviderId: "google",
          };
        // const googleresponse = {
        //     Name: response.profileObj.name,
        //     email: response.profileObj.email,
        //     token: response.googleId,
        //     Image: response.profileObj.imageUrl,
        //     ProviderId: 'google'
        // };
        socialLogin(googleresponse);
    }

    const responseGoogleFailure = (response) => {
        console.log(response, '>>>>>>responseGoogleFailure>>>>')
    }


    const socialLogin = (payload) => {
        dispatch(getCredentials((res) => {
            if (res.status == true) {
                let payload1 = {
                    "client_id": res.res.client_id,
                    "client_secret": res.res.client_secret,
                    "username": payload.email,
                    "unique_id": payload.token,
                    "platform": payload.ProviderId
                }
                dispatch(registerSocialLogin(payload1, (result) => {
                    setLoaded(true);
                    if (result.error) {
                        toast(result.error_description, {
                            position: "top-right",
                            autoClose: 10000,
                            type: 'error',
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                        });

                    } else if (result.access_token) {
                        toast('You are successfully logged into application.', {
                            position: "top-right",
                            autoClose: 10000,
                            type: 'success',
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                        });
                        updateBannerDetails(result.access_token)
                        sessionStorage.setItem("token",result.access_token)

                        // props.history.push('/');
                         props.closeLoginPopup(false);
                        // props.history.push('/');
                        window.location.href = `${connection.homePage}?access_token=${result.access_token}`
                       
                    }
                }));
            }
        }));
    }

    const TriggerFb = () => {
        setClickedFb(true)
    }


    return (

        <div className="login-popup login-popup-custom">
            <Form onSubmit={(event) => handleFormSubmit(event)} autoComplete="off">
                <div className="login_inner4 login_inner_new_design">
                    <h4 className={props?.customClass?.modalTop ? "modalh4 sign_in_title" :""}>Log in to<Link  onClick={()=>{window.location.href = connection.homePage}}><img className="e-logo" src={Elogo} alt="elogo" /></Link></h4>
                    <div style={{cursor:"pointer",padding:9}}  className="google_add">
                              <LoginSocialGoogle
                            //    client_id={"698956245459-a47h70am42631r6r2bvn0kfmj1qv5o8k.apps.googleusercontent.com"}
                                    client_id={"364132936223-1dm6pioltpokfbe9s7co6savjjlkd6n0.apps.googleusercontent.com"}
                                    onResolve={responseGoogle}
                                    onReject={responseGoogleFailure}
                                    redirect_uri = "https://eternaview.com/"
                                >
                                    <a ><img src={google} />Sign In with Google</a>
                                </LoginSocialGoogle>
                                {/* New Client:- 698956245459-a47h70am42631r6r2bvn0kfmj1qv5o8k.apps.googleusercontent.com */}
                        {/* <a href="#"><img src="assets/img/google.png" />Sign In with Google</a> */}
                        {/* <GoogleLogin
                            clientId="364132936223-1dm6pioltpokfbe9s7co6savjjlkd6n0.apps.googleusercontent.com"
                            buttonText="Sign In with Google"
                            disabled={false}
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}>
                        </GoogleLogin> */}
                    </div>
                    <div className="google_add fb_inner">
                        {/* <a href="#"><img src="assets/img/facebook1.png" />Sign In with Facebook</a> */}
                        {/*  <FacebookLogin
                                        appId="643095534158146"
                                        textButton="Sign In with Facebook"
                                        autoLoad={false}
                                        fields="name,email,picture"
                                        callback={responseFacebook}
                                        icon="fa-facebook" /> */}

                        {!clickedFb ? (
                            <>
                                <a onClick={TriggerFb} style={{ cursor: 'pointer' }}>
                                    <i className="fa fa-facebook" style={{ color: '#00008B', marginRight: '2px' }}></i>
                                    <span className="ml-2"> Sign In with Facebook</span></a>
                            </>
                        ) : null}
                        {clickedFb && <CustomFaceBookLogin props={props} />}

                    </div>
                    <p>Or Use Your Email Account</p>

                    <div className="input_outer99">
                        <input autoFocus={true} type="email" name="email" required placeholder="Email" value={email} onChange={handleEmailInput} />
                    </div>

                    <div className="input_outer99">
                        {inputtype == false &&
                            <input type="password" name="password" placeholder="Password" required value={password} onChange={handlePasswordInput} />
                        }
                        {inputtype == true &&
                            <input type="text" name="password" placeholder="Password" required value={password} onChange={handlePasswordInput} />
                        }

                        {inputtype == false && <img src={edit} onClick={showPassword} />}
                        {inputtype == true && <i className="fa fa-eye-slash" aria-hidden="true" onClick={showPassword}></i>}
                    </div>

                    <div className="checkbox custom_check">
                        <label className="container98">Remember me
                            <input type="checkbox" />
                            <span className="checkmark"></span>
                        </label>
                        <Link to="/oauth/forgot-password/">Forgot password?</Link>
                    </div>

                    <div className="log_outer">
                        {/* <button type="submit">Log in</button> */}
                        {loaded == true && <button className="Puchase_now_btn_new" type="submit">Log in</button>}
                        {loaded == false && <button className="Puchase_now_btn_new" type="button">Logging in...</button>}

                        <Loader loaded={loaded}></Loader>
                    </div>
                    <p className="new_user5">New User?<a data-toggle="modal" style={{color:"#1b9df5",cursor:"pointer"}} onClick={props.isSignupPop}
                        // Link to="/oauth/sign-up/"
                        > Register Now</a></p>
                </div>
            </Form>
        </div>
    )
};

export default LoginComponent;
