import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Form } from "reactstrap";
import { createGiftCheckoutSession,getCheckoutURL } from "../../redux/actions";
import moment from "moment";
import { Circles } from "react-loader-spinner";
const GiftPopUp2 = (props) => {
  const [startLoad,setStartLoad] = useState(false)
  const [formDetails,setFormDetails] = useState({recipient_email:"",recipient_confirm_email:""})
  const [checkValidMails,setMailValid] = useState({isRecipientEmailValid:null,isSenderMailValid:null})
  const dispatch = useDispatch()
  const auth = useSelector((state) => state);

  const actionOnSubmit = (e) =>{
    e.preventDefault()
    if(!checkValidMails.isRecipientEmailValid || !checkValidMails.isSenderMailValid ){
      toast.error("Please check E-mail")
      return
    }
    if(formDetails.recipient_email != formDetails.recipient_confirm_email){
      toast.error("Please check Recipient's E-mail")
      return
    }
    if(formDetails.recipient_confirm_email) delete formDetails.recipient_confirm_email
    formDetails.plan = props.PlanDetails.id
    setStartLoad(true)
    dispatch(
      createGiftCheckoutSession(auth.auth.loginUserToken, formDetails, (res) => {

          if(res.error){
            setStartLoad(false)
            if(res.error == "Not able to save the data"){
              toast.error("Please note that the intended recipient already has an active subscription")
            }
            else{
              toast.error(res.error)
            }
            
          }else{
          sessionStorage.setItem("giftRecipent",formDetails.recipient_email)
          dispatch(getCheckoutURL(auth.auth.loginUserToken, {"recipient_email":formDetails.recipient_email,lookup_key:props.PlanDetails.id}, (res) => {
            setStartLoad(false)
            window.location.href = res.session_url
          }))
          }
      })
    );
    props.state(false)
  }

  const handleChange = (event) =>{
    setFormDetails({...formDetails,[event.target.name]: event.target.value})
    validateEmail(event.target.value,event.target.name,"onchange")
  }

  const validateEmail = (email,type,action) =>{
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(action == "onchange" &&( emailRegex.test(email)== true)){
      if(type == "recipient_email"){
        setMailValid({...checkValidMails,isRecipientEmailValid: emailRegex.test(email)})
      }
      if(type == "sender_email"){
        setMailValid({...checkValidMails,isSenderMailValid: emailRegex.test(email)})
      }
    }else if(action !== "onchange" ){
      if(type == "recipient_email"){
        setMailValid({...checkValidMails,isRecipientEmailValid: emailRegex.test(email)})
      }
      if(type == "sender_email"){
        setMailValid({...checkValidMails,isSenderMailValid: emailRegex.test(email)})
      }
    }
   
    
  }

  return (
    <>
    {
        startLoad && <Circles wrapperClass="circle-spinner"/>
      }
         <div
      id="giftsPopup2"
      style={{
        display: props.isOpen ? "block" : "none",
      }}
      className={
        props.isOpen ? 
     "modal fade show" : "modal fade hide"
      }
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered gift_popup_div">
        <div className="modal-content">
            <button
              onClick={()=>{props.state(false)}}
              type="button"
              className="close gift_header_cross"
            >
              &times;
            </button>
          <div className="modal-body custom_body custom_modal_body">
            <div className="login-popup login-popup-custom gift_custom_login_popoup">
              <Form onSubmit={actionOnSubmit} autoComplete="off">
                <div className="login_inner4">
                  {/* <h4 className={"gift_popup_title"}>  add tittle for modal
                  </h4> */}
                  <p className="input_outer99_gifts">Your gift recipient's name*</p>
                  <div className="input_outer99 d-flex gift_input_div">
                    <input
                      onChange={handleChange}
                      autoFocus={true}
                      type="text"
                      name="recipient_first_name"
                      required
                      placeholder="First Name *"
                    />
                     <input
                     onChange={handleChange}
                     className="ml-2"
                      autoFocus={true}
                      type="text"
                      name="recipient_last_name"
                      placeholder="Last Name"
                    />
                  </div>
                  <p className="input_outer99_gifts">Your gift recipient's email *</p>
                  <div className="input_outer99 d-flex gift_input_div">
                    <input
                      onChange={handleChange}
                      autoFocus={true}
                      type="email"
                      name="recipient_email"
                      required
                      onBlur={(event)=>{validateEmail(event.target.value,event.target.name)}}
                      placeholder="email"
                    />
                  </div>
                  {
                    checkValidMails.isRecipientEmailValid === false &&
                    <p className="input_outer99_gifts_required">*Please enter a valid email</p>
                  }
                  <p className="input_outer99_gifts">Confirm recipient's email *</p>
                  <div className="input_outer99 d-flex gift_input_div">
                    <input
                     onChange={handleChange}
                      autoFocus={true}
                      type="email"
                      name="recipient_confirm_email"
                      required
                      placeholder="email"
                    />
                  </div>
                  {
                   formDetails.recipient_email != formDetails.recipient_confirm_email ?
                    <p className="input_outer99_gifts_required">*e-mail and confirm e-mail needs to be same</p>:""
                  }
                  <h4 className="gift_popup_title">Add a gift message</h4>
                  <p className="input_outer99_gifts">From *</p>
                  <div className="input_outer99 d-flex gift_input_div">
                    <input
                      onChange={handleChange}
                      autoFocus={true}
                      type="text"
                      name="sender_name"
                      required
                      placeholder="Names of all gifters"
                    />
                  </div>
                  <p className="input_outer99_gifts">Your (sender’s) email *</p>
                  <div className="input_outer99 d-flex gift_input_div">
                    <input
                      onChange={handleChange}
                      autoFocus={true}
                      type="email"
                      name="sender_email"
                      onBlur={(event)=>{validateEmail(event.target.value,event.target.name)}}
                      required
                      placeholder="email"
                    />
                  </div>
                  {
                    checkValidMails.isSenderMailValid === false &&
                    <p className="input_outer99_gifts_required">*Please enter a valid email</p>
                  }
                  <p className="input_outer99_gifts">Your message *</p>
                  <div className="input_outer99 d-flex gift_input_div">
                    <textarea
                    required
                    onChange={handleChange}
                    autoFocus={true}
                    type="text"
                    name="message"
                    placeholder="Your Message"
                    ></textarea>
                  </div>
             
                  <p className="new_user5 purchase_p_user">
                    <button className="Puchase_now_btn_new" type="submit">
                       Continue
                    </button>
                  </p>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
 
  );
};
export default GiftPopUp2;
