import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setNavigationBanner } from '../../redux/actions';
import thankyou_icon from "../../Images/thnku-icon.png";
import thankyou_text from "../../Images/thnku-text.png";
import FacebookPixel from '../../MetaComponents/Facebookpixel';


function Thankyou(props) {
    const dispatch = useDispatch();
    const paramvalue = useParams();
    const [redirect,setRedirect] = useState('/')
    const [paramUrl, setParamUrl] = useState('');
    const auth = useSelector(state => state);

    useEffect(() => {



        updateBannerDetails()
        if (auth.auth.loginUserToken == "") {
            props.history.push('/');
        }

        setParamUrl(paramvalue.id);
        if(paramvalue.id.includes("undefined")){
            setRedirect(`/home/profile/`)
        }else{
            setRedirect(`/create/eternaview/${paramvalue.id}`)
        }
    }, []);

    const updateBannerDetails = (status) =>{
        dispatch(setNavigationBanner( {is_banner:true},auth.auth.loginUserToken,(res)=>{
        //   console.log("Resp in banner ->",res);
        }))
      }
   
    return (
        <>
        {/* <script>
  fbq('track', 'Purchase');
</script> */}
   <FacebookPixel trackName="Purchase" obj={{value: 60, currency: "USD"}}/>
   {console.log(redirect,"<- redirect")}
        <section class="thankyou-outer">
            <div class="container inner-container">
                <div class="row">
                    <div class="col-md-8 offset-md-2 col-sm-12">
                        <div class="login-inner thankyou-outer">
                            <div class="thankyou-upper">
                                <img src={thankyou_icon} alt="" />
                            </div>
                            <div class="thankyou-bottom">
                                <img src={thankyou_text} alt="" />
                                <p class="p-text">Your payment has been received. You may now move on to recording.</p>
                                <Link to={redirect}><button class="Puchase_now_btn_new">Continue</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
};

export default Thankyou;
