const environment= process.env.REACT_APP_ENVIRONMENT; //development
const FaceBook_Token = "EABVrVD4Ut9ABO4RIx47Mtk2xENj7s66sJrPdEfx2IZAWtnqupaf3ZBlhE60E8nsEX3JDSFNmTboydB2j3M7KH8QjwxTS2JzkaoyeN0maq2yNwbtZAGkCyREwmk6BlT2eSzOSxdpDBSOz1eLEi3DKzCHCGKSQVdkedP08w4hKXXw8ZBAZCNL4tNpeuEUiGkQqT2gZDZD"
if (environment=== "production") {
    module.exports = {
        FaceBook_Token,
        homePage:"https://eternaview.com/",
        environment,
        REACT_APP_BASE_ENDPOINT: "https://eternabackend.eternaview.com",
        production_time: "6-10 business days after artwork approval",
        FRONTEND_ENDPOINT: "https://eternaview.com"
    };
} else {
    module.exports = {
        FaceBook_Token,
        homePage:"https://wp-eternaview-com.stackstaging.com/",
        environment,
        REACT_APP_BASE_ENDPOINT: "https://eternabackendstaging.eternaview.com",
        // REACT_APP_BASE_ENDPOINT: "https://backend-testing2.eternaview.com",
        production_time: "6-10 business days after artwork approval",
        FRONTEND_ENDPOINT: "https://staging.eternaview.com/"  
    };
}
//* https://eternabackend.eternaview.com
//* https://eternabackend.itechnolabs.tech
// https://backend-testing2.eternaview.com/ 